import React, { useState } from "react";
import { Box, Button, Grid, Card } from "@mui/material";

const TpoRankHolders = ({ RankUsers }) => {

  const [page, setPage] = useState(0);
  const itemsPerPage = 6;

 
  return (
    <Box sx={{ marginBottom: 3 ,backgroundColor: "white" }}>
      <div style={{padding:"10px" ,overflow:"hidden"}}>
        <h5>Achievers</h5>
        <div className="achievers-container">
          {/* <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 }}>
            <Button
              variant="contained"
              onClick={handlePrev}
              disabled={page === 0}
              style={{display:"flex", backgroundColor:"white", marginTop:"25px"}}
            >
              <img src="/images/prev-icon.svg" alt="" style={{width:"50px"}}/>
            </Button>
          </Box> */}
        
            {RankUsers?.map((achiever, index) => (
              <div key={index} >
                <Card className="achiever-card">
                  <div
                    className="achiever-card-header"
                    style={{ backgroundColor: achiever.color ?? "#e6dbff" }}
                  >
                    <div className="achiever-image-container">
                      <img
                        src={achiever.image ?? "/images/user-profile.png"}
                        className="achiever-image"
                        alt="Achiever"
                      />
                    </div>
                  </div>
                  <div>
                    <img
                      src={achiever.rankImage ?? "/images/rank.jpg"}
                      className="achiever-rank-image"
                      alt="Rank"
                    />
                  </div>
                  <div className="achiever-user-info">
                    <p className="achiever-username">{achiever.user}</p>
                    <div className="rank-badge">{achiever.rank}</div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
          {/* <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 , marginTop:"25px"}}>
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={(page + 1) * itemsPerPage >= RankUsers.length}
              style={{display:"flex", backgroundColor:"white"}}
            >
              <img src="/images/nxt-icon.svg" alt="" style={{width:"50px" }}/>
            </Button>
          </Box> */}
      
      </div>
    </Box>
  );
};

export default TpoRankHolders;
