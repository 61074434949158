import React from 'react';

const CustomProgressBar = ({ completed = 0, acheived = 0, totalPv = 0, boolean = false, expired = false }) => {
  const Parentdiv = {
    borderRadius: "40px",
    textAlign: "right",
    backgroundColor:  "whitesmoke",
    margin: "0px",
    height: "30px",
    display: "flex",
    justifyContent: "end"
  }

  const Childdiv = {
    borderRadius: "40px",
    textAlign: "right",
    backgroundColor:  expired === true && boolean=== false ?  "rgba(227, 67, 67, 0.77)" : "#AFE1AF",
    margin: "0px",
    height: "30px",
    width: `${completed}%`,
    // display: "flex",
    // justifyContent: "space"
  }

  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 600,
    opacity: ".6"
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={Parentdiv}>
        <div style={Childdiv}>
        </div>
      </div>
      <div style={{
        justifyContent: "space-between",
        display: "flex",
        marginTop: "-37px"
      }}>
        {expired === true  && boolean === false? (
          <><p style={{
            color: "#000000b5",
            display: "flex",
            justifyContent: "center",
            padding: "7px",
            width: "100%",
          }}>expired</p></>
        ) : (
          <> <span style={progresstext}>{acheived}</span>
            <span style={progresstext}>{boolean == true ? `MAX ${totalPv}` : boolean === false ? totalPv : ""} </span></>
        )}
      </div>
    </div >

  )
}
export default CustomProgressBar;
