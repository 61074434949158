import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
const ReplicaRegisterModal = ({ show, handleClose, data }) => {
  const { t } = useTranslation();
  const DisplayDataWithTags = ({ htmlContent }) => {
    // Sanitize the HTML content using DOMPurify
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    return (
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    );
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('termsAndCondition')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DisplayDataWithTags htmlContent={data} />
      </Modal.Body>
    </Modal>
  );
};

export default ReplicaRegisterModal;
