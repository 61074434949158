import React, { useEffect, useState } from "react";
// import Web3 from "web3";
import { HOSTING } from "../../config/config";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import ABI_USDT from './abi/ABI_USDT.json'
import {
    useAccount,
    useReadContract,
    useSwitchChain,
    useTransactionConfirmations,
    useWaitForTransactionReceipt,
    useWriteContract,
} from "wagmi";
import { parseEther, parseUnits } from 'viem'
import { useWeb3ModalState } from '@web3modal/wagmi/react'

const supportedChain = HOSTING == "LIVE" ? 56 : 97 //bsc_test , 11155111 sepolia

const USDTTransfer = ({
    onClick = () => null,
    oninitiate,
    onsubmit,
    amount,
    toAddress,
    title,
    style,
    disabled = false,
    loading,
    active
}) => {

    // amount = 0.001;
    // toAddress = "0x4B04b0bD233E5cf6EBf7E293124143eaf1077f23";

    const usdt_bsc = "0x55d398326f99059fF775485246999027B3197955";
    const usdt_test_bsc = "0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48";
    // const usdt_test_bsc = "0xa3b01b8B0cA187540ECff3c97f0f6FFC6b2255cF"; // Sepolia


    const contractaddress = HOSTING == "LIVE" ? usdt_bsc : usdt_test_bsc;
    const contractabi = HOSTING == "LIVE" ? ABI_USDT : ABI_USDT;
    const { open, close } = useWeb3Modal();
    const { address, isConnecting, isDisconnected } = useAccount();

    const { chains, switchChain, error: switchChainError } = useSwitchChain()
    const { selectedNetworkId } = useWeb3ModalState()




    const {
        data: hash,
        writeContract,
        isPending,
        onSccess,
        error,
    } = useWriteContract();

    const {
        data: successData,
        isLoading: isConfirming,
        isSuccess: isConfirmed,
        isError,
    } = useWaitForTransactionReceipt({
        hash,
    });

    useEffect(() => {
        if (isConfirmed) {
            console.log("---SUCCESS----", { isConfirmed, successData });
            onsubmit({
                status: true,
                message: "Transaction Success.",
                data: successData,
            });
        }
    }, [isConfirmed, successData]);

    // useEffect(() => {
    //     if (error) {
    //         console.log("ERROR----", error.shortMessage || error.message);
    //         onsubmit({
    //             status: false,
    //             message: error.shortMessage || error.message,
    //             data: error.shortMessage || error.message,
    //             tag: 'useEffect error'
    //         });
    //     }
    // }, [error]);


    const { data: decimals } = useReadContract({
        abi: contractabi,
        address: contractaddress,
        functionName: 'decimals',
    })


    // const value = Web3.utils.toWei(amount.toString(), "ether");
    const value = parseUnits(amount.toString(), decimals)

    // console.log({ decimals, value, address });

    // const { data } = useReadContract({
    //     abi: contractabi,r
    //     address: contractaddress,
    //     functionName: 'balanceOf',
    //     args: [address]
    // })
    // console.log("-----------------", error);


    const startTransaction = () => {
        writeContract(
            {
                abi: contractabi,
                address: contractaddress,
                functionName: "transfer",
                args: [toAddress, value],
            },
            {
                onSettled: (hash) => {
                    // console.log("SETTLED", hash);
                    if (hash) {
                        oninitiate({
                            status: true,
                            message: "Transaction initiated.",
                            hash,
                        });
                    }
                },
                onError: (error) => {
                    // console.log("ERROR----", error);
                    onsubmit({
                        status: false,
                        message: error.shortMessage || error.message,
                        tag: 'on transfer error'
                    });
                },
                // onSuccess: (result) => {
                //   // console.log("SUCCESS----", result);
                //   onsubmit({
                //     status: true,
                //     message: "Transaction Success.",
                //     data: result,
                //   });
                // },
            }
        );
    };


    const onclick = () => {
        if (loading) return

        if (address && selectedNetworkId != supportedChain) {
            switchChain({
                chainId: supportedChain,
            })
            return
        }

        if (address) {
            startTransaction()
            onClick?.()
        } else {
            open()
        }
    }

    // return <Button
    //     onClick={onclick}
    //     title={address ? title ?? "Send USDT" : "Connect Wallet"}
    //     className="p-4 w-full bg-gradient-to-r from-primary to-secondary text-textColor pr-2"
    //     type="primary"
    //     disabled={disabled}
    //     loading={loading}
    // />

    return (
        <div>
            <button
                // disabled={isPending || isConfirming || metaMaskLoading || disabled}
                className="btn btn-sm btn-primary mt-3"
                onClick={onclick}
                disabled={active}
                style={{
                    border: "2px solid black",
                    paddingRight: 50,
                    paddingLeft: 50,
                    ...style,
                }}
            > {address ? <label>{loading ? "proccessing" : selectedNetworkId != supportedChain ? `Switch chain to ${chains[0]?.name} ` : title  ?? "Send USDT"}</label> : <label>Connect Wallet</label>}
            </button>
        </div>
    );
};

export default USDTTransfer;

