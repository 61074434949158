import React from "react";
import CustomProgressBar from "./Progress";
function ReplicaAndLeadLink(travel) {
  { console.log(travel); }
  return (
    <>
      <div className="border" style={{ marginTop: "15px" }}>
        <p style={{ fontSize: "20px" }}>World Tour Promotion</p>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px 0px 5px", opacity: ".7" }}>
          <p> Completed </p>
          <p> Required  </p>
        </div>

        <CustomProgressBar
          completed={travel?.travel?.expired === true ? 100 : travel?.travel?.percentage }
          acheived={travel?.travel?.sum ?? 0}
          totalPv={travel?.travel?.travelBonusAmount ?? 0}
          boolean={false}
          expired = {travel?.travel?.expired }
        />
        <div style={{ margin: "10px 0px 0px 0px" }}>
          <div className="rankCriteria">
            <p style={{ padding: " 2px 0px 0px" }} > Leg 1 </p>
            <div className="bar">
              <CustomProgressBar
                completed={(travel?.travel?.strongLeg / travel?.travel?.strongLeg_re) * 100 ?? 0}
                acheived={travel?.travel?.strongLeg ?? 0}
                totalPv={travel?.travel?.strongLeg_re ?? 0}
                boolean={true}
                expired = {travel?.travel?.expired }
              />
            </div>
          </div>

          <div className="rankCriteria">
            <p style={{ padding: " 2px 0px 0px" }} > Leg 2 </p>
            <div className="bar">
              <CustomProgressBar
                completed={(travel?.travel?.weakLeg / travel?.travel?.weakLeg_re) * 100 ?? 0}
                acheived={travel?.travel?.weakLeg ?? 0}
                totalPv={travel?.travel?.weakLeg_re ?? 0}
                boolean={true}
                expired = {travel?.travel?.expired }
              />
            </div>
          </div>

          <div className="rankCriteria">
            <p style={{ padding: " 2px 0px 0px" }} > Other Leg </p>
            <div className="bar">
              <CustomProgressBar
                completed={(travel?.travel?.other / travel?.travel?.other_re) * 100 ?? 0}
                acheived={travel?.travel?.other ?? 0}
                totalPv={travel?.travel?.other_re ?? 0}
                boolean={null}
                expired = {travel?.travel?.expired }
              />

            </div>
          </div>
        </div>

        <div className="rule">
          <p> 40%  Rule </p>
        </div>

      </div>
    </>
  );
}

export default ReplicaAndLeadLink;





