import React, { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";



const TimeAgo = ({ timestamp }) => {
  const [timeAgo, setTimeAgo] = useState('');

  const formatDate = () => {
    const currentDate = new Date();
    const targetDate = new Date(timestamp);
    const timeDifference = currentDate - targetDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (seconds < 60) {
      return `${seconds} sec${seconds !== 1 ? 's' : ''} `;
    } else if (minutes < 60) {
      return `${minutes} min${minutes !== 1 ? 's' : ''} `;
    } else if (hours < 24) {
      return `${hours} hr${hours !== 1 ? 's' : ''}`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? 's' : ''}`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? 's' : ''}`;
    } else {
      const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
      return targetDate.toLocaleDateString('en-US', options);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const distance = formatDate(new Date(timestamp));
      setTimeAgo(distance);
    }, 1000);

    return () => clearInterval(interval);
  }, [timestamp]);

  return <span>{timeAgo}</span>;
};




const NewUser = ({ newUser }) => {
  return (
 
      <div className="newUser">
        <div className="newUser-container">
          {newUser?.map((user, index) => {
            const targetDate = new Date(user.date_of_joining);
  
            return (
              <div key={index} className="userContainer">
                <div className="flag" >
                  <ReactCountryFlag
                    countryCode={user.code ?? "US"}
                    svg
                    style={{ height: "100%", width: "100%", transform:"scale(1.37)"}}
                    title={user.code ?? "US"}
                  />
                </div>
                <div className="flag-text">
                  <p>{user.username}</p>
                  <p><TimeAgo timestamp={targetDate} /></p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
  );
  
};

export default NewUser;
