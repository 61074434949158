import React, { useState, useEffect } from "react";
// import OptionsButton from "../../components/Common/buttons/OptionsButton";
import EwalletTiles from "../../components/Ggwallet/EwalletTiles";
import EwalletTable from "../../components/Ggwallet/EwalletTable";
// import FundTransfer from "../../components/Ewallet/FundTransfer";
import { NavLink } from "react-router-dom";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TableMain from "../../components/Common/table/TableMain";

const RealWalletLayout = () => {
  const [showTransfer, setShowTransfer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectStatement, setSelectedStatement] = useState(true);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("statement");

  const setApiTab = (tab) => {
    setSelectedStatement(true);
  };

  const statement = ApiHook.CallRealwalletStatement(
    currentPage,
    itemsPerPage,
    selectStatement
  );


  const headers =
    activeTab !== "my_earnings"
      ? [t("description"), t("amount"), t("transaction_date"), t("balance")]
      : [
        t("description"),
        t("total_amount"),
        t("TDS"),
        t("service_charge"),
        t("amount_payable"),
        t("transaction_date"),
      ];

  const style = {
    position: "absolute",
    inset: "0px auto auto 0px, margin: 0px",
    transform: "translate(190px, 42px)",
  };

  const handleEwalletTransfer = () => {
    setShowTransfer((prevShowTransfer) => !prevShowTransfer);
  };
  const ewallet = ApiHook.CallRealwalletTiles();
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );

  return (
    <>
      <div className="page_head_top"> Real Estate Wallet </div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4">
            <NavLink to={"/e-wallet"} className="btn_ewallt_page">
              {t("ewallet")}
            </NavLink>
            <NavLink className="btn_ewallt_page" activeclassname="active">
            Real Estate Wallet
            </NavLink>
            <NavLink to={"/gg-wallet"} className="btn_ewallt_page">
              GGFC wallet
            </NavLink>
            <NavLink to={"/lottery-wallet"} className="btn_ewallt_page">
              Game Wallet
            </NavLink>
          </div>
          {/* <div className="col-md-4 text-end">
            <OptionsButton
              title={"ewallet_fund_transfer"}
              handleOpen={handleEwalletTransfer}
              style={style}
              type={"ewallet"}
            />
          </div> */}
        </div>
      </div>
      <EwalletTiles
        tiles={ewallet?.data}
        currency={userSelectedCurrency}
        conversionFactor={conversionFactor}
      />
      <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
          <div className="ewallet_table_section_cnt_tab_head">
            <NavLink className={`ewallet_tab_btn ${"active"}`}>
              {t("statement")}
            </NavLink>
          </div>
        </div>
        <div className="table-responsive min-hieght-table">
          <TableMain
            headers={headers}
            data={statement?.data?.data}
            startPage={1}
            currentPage={statement?.data?.currentPage}
            totalPages={statement?.data?.totalPages}
            setCurrentPage={setCurrentPage}
            type={"ewallet"}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            activeTab={setApiTab}
            setApiTab={setApiTab}
          />
        </div>
      </div>

      {/* <FundTransfer
        balance={ewallet?.data?.balance}
        show={showTransfer}
        handleClose={handleEwalletTransfer}
        currency={userSelectedCurrency}
      /> */}
    </>
  );
};

export default RealWalletLayout;
