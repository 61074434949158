import { EwalletService } from "../../services/ewallet/ewallet";


export const  GGTiles = async () => {
    try {
        const response = await EwalletService.callgTiles();
        return response
    } catch (error) {
        return error.message
    }
}

export const  RealTiles = async () => {
    try {
        const response = await EwalletService.callRealTiles();
        return response
    } catch (error) {
        return error.message
    }
}
export const LotteryTiles = async () => {
    try {
        const response = await EwalletService.callLotteryTiles();
        return response
    } catch (error) {
        return error.message
    }
}

export const GStatement = async (page, itemsPerPage) => {
    try {
        const response = await EwalletService.callGStatement(page, itemsPerPage);
        return response
    } catch (error) {
        return error.message
    }
}
export const LStatement = async (page, itemsPerPage) => {
    try {
        const response = await EwalletService.callLStatement(page, itemsPerPage);
        return response
    } catch (error) {
        return error.message
    }
}
export const RStatement = async (page, itemsPerPage) => {
    try {
        const response = await EwalletService.callRStatement(page, itemsPerPage);
        return response
    } catch (error) {
        return error.message
    }
}

export const TransferHistory = async (page, itemsPerPage, selectedCategory, startDate, endDate) => {
    try {
        const response = await EwalletService.callTransferHistory(page, itemsPerPage, selectedCategory, startDate, endDate);
        return response
    } catch (error) {
        return error.message
    }
}

export const PurchaseHistory = async (page, itemsPerPage) => {
    try {
        const response = await EwalletService.callPurchaseHistory(page, itemsPerPage);
        return response
    } catch (error) {
        return error.message
    }
}

export const MyEarnings = async (page, itemsPerPage, selectedCategory, startDate, endDate) => {
    try {
        const response = await EwalletService.callMyEarnings(page, itemsPerPage, selectedCategory, startDate, endDate);
        return response
    } catch (error) {
        return error.message
    }
}

export const FundTransfer = async (data) => {
    try {
        const response = await EwalletService.callFundTransfer(data);
        return response
    } catch (error) {
        return error.message
    }
}

export const EwalletBalance = async () => {
    try {
        const response = await EwalletService.callEwalletBalance();
        return response
    } catch (error) {
        console.log(error.message);
    }
}