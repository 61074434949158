import React, { useEffect, useRef ,useState} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Popover, OverlayTrigger } from "react-bootstrap";

function UserDropdown({ props ,replicaLink}) {
  const { t } = useTranslation();
  const progressBarRef = useRef(null);
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );
  const [activeTab, setActiveTab] = useState("replica");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const strokeDashOffsetValue =
      100 - (props?.productValidity?.packageValidityPercentage ?? 100);
    progressBarRef.current.style.strokeDashoffset = strokeDashOffsetValue;
  }, [props?.productValidity?.packageValidityPercentage]);

  const copyToClipboard = async (text) => {
    if (navigator && navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
        // Reset copied status after a delay (e.g., 2 seconds)
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (error) {
        console.error("Clipboard copy failed:", error);
      }
    } else {
      // Clipboard API is not supported, provide a fallback method
      try {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        setCopied(true);
        // Reset copied status after a delay (e.g., 2 seconds)
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (error) {
        console.error("Fallback clipboard copy failed:", error);
      }
    }
  };

  const renderPopover = (content) => (
    <Popover>
      <Popover.Body>{`${content}`}</Popover.Body>
    </Popover>
  );

  const renderSocialIcons = (links) => {
    return links?.map((icon, index) => (
      <React.Fragment key={index}>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={renderPopover(copied ? "Copied" : icon.name)}
        >
          {(icon?.name === "Replica Link" || icon?.name === "Lead Capture Link") ? (
            <div
              className="replica_link_sec_ico"
              onClick={() => copyToClipboard(icon.link)}
            >
              <img src={`images/${icon.icon}`} alt={icon.name} />
            </div>
          ) : (
            <a
              className="replica_link_sec_ico"
              target="_blank"
              href={icon.link}
            >
              <img src={`images/${icon.icon}`} alt={icon.name} />
            </a>
          )}
        </OverlayTrigger>
      </React.Fragment>
    ));
  };



  return (
    <div>
      <aside className="left_sidebar"></aside>
      <div className="rightSide_top_user_dropdown">
        <div className="rightSide_top_user_dropdown_avatar_sec">
          <div className="rightSide_top_user_dropdown_avatar">
            <img
              src={props?.image ? props?.image : "/images/user-profile.png"}
              alt=""
            />
            {moduleStatus?.kyc_status === 1 ? (
              <div className="kyc_vrfd">
                {props?.kycStatus ? (
                  <img src="/images/kyc_vrfd.svg" alt="" />
                ) : (
                  <img src="/images/kyc_not_vrfd.png" alt="" />
                )}
              </div>
            ) : (
              <div className="kyc_vrfd">
                <img src="/images/kyc_vrfd.svg" alt="" />
              </div>
            )}
            <svg
              className="profile_progress"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-1 -1 34 34"
            >
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                className="progress-bar__background"
              />
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                className="progress-bar__progress js-progress-bar"
                ref={progressBarRef}
              />
            </svg>
          </div>
        </div>
        <div className="rightSide_top_user_dropdown_nameBOx">
          <div className="rightSide_top_user_dropdown_name">
            {props?.fullname}
          </div>
          <div className="rightSide_top_user_dropdown_id">
            {props?.username}
          </div>
          {moduleStatus?.product_status === 1 && (
            <div className="rightSide_top_user_dropdown_package">
              {props?.packageName}
            </div>
          )}
        </div>
      </div>

      <div className="top_right_personal_dtl_box_sec">
        <div className="top_right_personal_dtl_box border-sprt">
          <span style={{display:"flex" ,justifyContent:"center"}}>{t("personalPV")}</span>
          <strong style={{display:"flex" ,justifyContent:"center"}}>{props?.personalPv ?? 0}</strong>
        </div>
        <div className="top_right_personal_dtl_box">
          <span style={{display:"flex" ,justifyContent:"center"}}>{t("groupPV")}</span>
          <strong style={{display:"flex" ,justifyContent:"center"}}>{props?.groupPv ?? 0}</strong>
        </div>
      </div>
     
        {!!moduleStatus?.replicated_site_status && activeTab === "replica" && (
          <div className="replica_link_sec">
            <div className="replica_link_sec_row" style={{ justifyContent: "center" }}>
              {renderSocialIcons(replicaLink)}
            </div>
          </div>
        )}
    </div>
  );
}

export default UserDropdown;





