import React from "react";
import UserDropdown from "./DashboardUserDropdown";
import DoughnutChart from "./payoutOverviewChart";
import LinkSection from "./UserLinks";
import RankSection from "./RankSection";
import { useSelector } from "react-redux";
import CustomProgressBar from "./Progress";
const RightContentSection = ({ props }) => {
  const currency = useSelector(state => state.user?.selectedCurrency);
  const conversionFactor = useSelector((state) => state.user?.conversionFactor);
  return (
    <>
      <UserDropdown
        props={props?.userProfile}
        payoutTab={props?.payoutOverview}
        replicaLink={props?.replicaLink}
        leadCaptureLink={props?.leadCaptureLink}
        currency={currency}
        conversionFactor={conversionFactor}
      />
      <RankSection rank={props?.rank} />
      <DoughnutChart
        pending={props?.payoutDoughnut?.pending}
        approved={props?.payoutDoughnut?.approved}
        payoutPaid={props?.payoutDoughnut?.paid}
        currency={currency}
        conversionFactor={conversionFactor}
        replicaLink={props?.replicaLink}
        capping = {props?.capping}    
        cappingThreshold = {props?.cappingThreshold}  />
      <LinkSection travel = {props?.travel} />
    </>
  );
};

export default RightContentSection;
