import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DownLoad = ({ show, handleClose, currentRank, handleDownload }) => {
    const { t } = useTranslation();
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title as={"h5"}>{t("rank")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4">
                        <div className="newsSubBg" onClick={() => handleDownload(currentRank?.certificate)}>
                            <img src={currentRank?.certificate} alt="" style={{ width: "350px" }} />
                            <div className="col-md-8">
                                <i className="fa fa-download" />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DownLoad;
