import React from "react";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
const QuickBalance = ({ tiles, currency, conversionFactor }) => {
  const navigate = useNavigate();

  const handleClick = async (path) => {
    console.log(path)
    navigate(path);
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="quick_balance_section">
        <div className="quick_balance_section_row">
          <div className="quick_balance_Box">
            <div className="quick_balance_Box_ico">
              <img src="/images/balance_commision_ico.svg" alt="" />
            </div>
            <div className="quick_balance_Box_cnt">
              <span>Total Bonus</span>
              <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                } ${CurrencyConverter(
                  tiles?.commission,
                  conversionFactor
                )}`}</strong>
            </div>
          </div>
          <div className="quick_balance_Box">
            <div className="quick_balance_Box_ico">
              <img src="/images/balance_ewallet_ico.svg" alt="" />
            </div>
            <div className="quick_balance_Box_cnt">
              <span>Available Bonus</span>
              <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                } ${CurrencyConverter(
                  tiles?.ewallet ?? 0,
                  conversionFactor
                )}`}</strong>
            </div>
          </div>
          <div className="quick_balance_Box">
            <div className="quick_balance_Box_ico">
              <img src="/images/withd.svg" alt="" />
            </div>
            <div className="quick_balance_Box_cnt">
              <span>Total withdraw</span>
              <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                } ${CurrencyConverter(
                  tiles?.totalDebit ?? 0,
                  conversionFactor
                )}`}</strong>
            </div>
          </div>
        </div>
      </div>
      <div className="quick_balance_section">

        <div className="quick_balance_section_row">
          <div className="quick_balance_Box" onClick={() => handleClick("/lottery-wallet")}>
            <div className="quick_balance_Box_ico">
              <img src="/images/game1.svg" alt="" />
            </div>
            <div className="quick_balance_Box_cnt">
              <span>Game Wallet</span>
              <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                } ${CurrencyConverter(
                  tiles?.lotteryWalletBalance ?? 0,
                  conversionFactor
                )}`}</strong>
            </div>

          </div>
          <div className="quick_balance_Box"  onClick={() => handleClick("/gg-wallet")}>
            <div className="quick_balance_Box_ico">
              <img src="/images/gg.svg" alt="" />
            </div>
            <div className="quick_balance_Box_cnt">
              <span>GGFC Wallet</span>
              <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                } ${CurrencyConverter(
                  tiles?.ggWalletBalanceAmount ?? 0,
                  conversionFactor
                )}`}</strong>
            </div>
          </div>
          <div className="quick_balance_Box" onClick={() => handleClick("/real-wallet")}>
            <div className="quick_balance_Box_ico">
              <img  src="/images/real.svg" alt="" />
            </div>
            <div className="quick_balance_Box_cnt" >
              <span>Real Estate Wallet</span>
              <strong >{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                } ${CurrencyConverter(
                  tiles?.realEstateWalletBalance ?? 0,
                  conversionFactor
                )}`}</strong>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default QuickBalance;
