import React, { useEffect, useState } from "react";
// import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import {
    PhoneInput,
    defaultCountries,
    parseCountry,
  } from 'react-international-phone';

const Phone = ({ defaultCountry = "us", value, onChange }) => {

    const countries = defaultCountries.filter((country) => {
        console.log(parseCountry(country),"22222222222");
        const { iso2 } = parseCountry(country);
        return true;
      });
      console.log("111111111111111",);
    const [key,setKey] = useState(0)
    useEffect(() => {
        console.log(defaultCountry, "countryCode");
        setKey((key)=>key+1)
    }, [defaultCountry]);

    return (
        <div>
            <PhoneInput
                key={key}
                defaultCountry={defaultCountry}
                value={value}
                onChange={onChange}
                countries={countries}
            />

        </div>
    );
};

export default Phone;
