import CrmService from "../../services/crm/crm";


export const CrmTiles = async () => {
    try {
        const response = await CrmService.crmTiles();
        return response
    } catch (error) {
        console.log(error.message);
        return error.message
    }
}

export const CrmGraph = async () => {
    try {
        const response = await CrmService.crmGraph();
        return response
    } catch (error) {
        console.log(error.message);
        return error.message
    }
}

export const FollowupToday = async (page, itemsPerPage) => {
    try {
        const response = await CrmService.followupToday(page, itemsPerPage);
        return response
    } catch (error) {
        console.log(error.message);
        return error.message
    }
}

export const RecentLeads = async (page, itemsPerPage) => {
    try {
        const response = await CrmService.recentLeads(page, itemsPerPage);
        return response
    } catch (error) {
        console.log(error.message);
        return error.message
    }
}

export const MissedFollowup = async (page, itemsPerPage) => {
    try {
        const response = await CrmService.missedFollowup(page, itemsPerPage);
        return response
    } catch (error) {
        console.log(error.message);
        return error.message
    }
}


export const ViewLeads = async (data) => {
    try {
        const response = await CrmService.viewLeads(data.searchTag,data.fromDate,data.toDate,data.nextFromDate,data.nextToDate,data.level_of_interest,data.country,data.leadStatus,data.statusFromDate,data.statusToDate);
        return response
    } catch (error) {
        console.log(error.message);
        return error.message
    }
}

export const EditCrmLead = async (updatedLead) => {
    try {
        const response = await CrmService.editCrmLead(updatedLead);
        return response
    } catch (error) {
        console.log(error.message);
        return error.message
    }
}