import React from "react";
import QuickBalance from "../../components/Dashboard/DashboardTiles";
import JoiningChart from "../../components/Dashboard/JoiningChart";
import NewUser from "../../components/Dashboard/NewUser";
import EarningsExpenses from "../../components/Dashboard/Earnings";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RankComponent from "../../components/Dashboard/RankComponent";
import TpoRankHolders from "../../components/Dashboard/Marquee";

const Dashboard = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const joiningChartData = useSelector(
    (state) => state.dashboard?.dashboardOne
  );
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );

  // --------------------------------------------- API -------------------------------------------------
  const dashboard = ApiHook.CallDashboardTiles();
  const dashboardDetails = ApiHook.CallDashboardDetails();


  return (
    <>
      <div className="page_head_top">{t("dashboard")}</div>
      <div className="center_content_head">
        <span>
          {t("welcome")} {user?.fullName}{" "}
        </span>
      </div>
      <NewUser newUser={dashboardDetails?.data?.newUser} />
      <QuickBalance
        tiles={dashboard?.data}
        currency={userSelectedCurrency}
        conversionFactor={conversionFactor}
      />
      <div className="joining_Teammbr_section">
        {dashboardDetails?.data?.rankUsers && (
          <TpoRankHolders RankUsers={dashboardDetails?.data?.rankUsers} />
        )}
        <div className="row">
          <JoiningChart charts={joiningChartData} />
        </div>
      </div>

      <div className="team_members_earning_section">
        <div className="row">
          <EarningsExpenses
            earnings={dashboardDetails?.data?.topCountries}
            currency={userSelectedCurrency}
            conversionFactor={conversionFactor}
          />
           {!!moduleStatus?.rank_status && (
            <RankComponent
              ranks={dashboardDetails?.data?.fiveRankers}
            />
          )}
        </div>

      </div>
    </>
  );
};

export default Dashboard;
