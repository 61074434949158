import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import CustomProgressBar from "./Progress";
function RankSection(rank) {
  return (
    <>
      <div className="rankSection">
        <div className="rankCard">
          <div className="rankDetails">
            <img
              src={rank?.rank?.currentRank?.image ? rank?.rank?.currentRank?.image : "/images/rank.jpg"}
              className="rankImage"
            />
            <div className="rankInfo">
              <p className="rankType">Current Rank</p>
              {rank?.rank?.currentRank?.name ? (<p className="rankName">{rank?.rank?.currentRank?.name}</p>) :
                (<p> Associate</p>)}
            </div>
          </div>
        </div>
        <div className="rankCard">
          <div className="rankDetails">
            <img
              src={rank?.rank?.nextRank?.image ? rank?.rank?.nextRank?.image : "/images/rank.jpg"}
              className="rankImage"
            />
            <div className="rankInfo">
              <p className="rankType">Next Rank</p>
              <p className="rankName">{rank?.rank?.nextRank?.name ?? "No Next Rank"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="border">
        <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px 0px 5px", opacity: ".7" }}>
          <p> Completed </p>
          <p> Required  </p>
        </div>
        <CustomProgressBar
          completed={rank?.rank?.percentage ?? 0}
          acheived={rank?.rank?.sum ?? 0}
          totalPv={rank?.rank?.totalPv ?? 0}
          boolean={false}
        />
        <div style={{ margin: "10px 0px 0px 0px" }}>
          <div className="rankCriteria">
            <p style={{ padding: " 2px 0px 0px" }} > Leg 1 </p>
            <div className="bar">
              <CustomProgressBar
                completed={(rank?.rank?.strongLeg / rank?.rank?.strongLeg_re) * 100 ?? 0}
                acheived={rank?.rank?.strongLeg ?? 0}
                totalPv={rank?.rank?.strongLeg_re ?? 0}
                boolean={true}
              />
            </div>
          </div>

          <div className="rankCriteria">
            <p style={{ padding: " 2px 0px 0px" }} > Leg 2 </p>
            <div className="bar">
              <CustomProgressBar
                completed={(rank?.rank?.weakLeg / rank?.rank?.weakLeg_re) * 100 ?? 0}
                acheived={rank?.rank?.weakLeg ?? 0}
                totalPv={rank?.rank?.weakLeg_re ?? 0}
                boolean={true}
              />
            </div>
          </div>

          <div className="rankCriteria">
            <p style={{ padding: " 2px 0px 0px" }} > Other Leg </p>
            <div className="bar">
              <CustomProgressBar
                completed={(rank?.rank?.other / rank?.rank?.other_re) * 100 ?? 0}
                acheived={rank?.rank?.other ?? 0}
                totalPv={rank?.rank?.other_re ?? 0}
                boolean={null}
              />

            </div>
          </div>
        </div>

        <div className="rule">
          <p> 40%  Rule </p>
        </div>
      </div>
    </>
  );
}

export default RankSection;





