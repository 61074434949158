
// const HOSTING = "DEMO" ;
const HOSTING = "LIVE" ;
// const BASE_URL = "http://192.168.9.47:5006/api"; //local
// const BASE_URL = "http://162.19.146.135:1398/api/" //demo
// const BASE_URL = "http://97.74.93.122:5000/" //live ip
const BASE_URL = "https://office.futuregoldengate.com/api"
const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"
export { HOSTING, BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID  }  
